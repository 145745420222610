import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import { Layout, Title, Subtitle, Listing, Wave } from '../components'
import website from '../../config/website'
import theme from '../styles/theme'
import nicer from '../utils/nicer'

export default function Index({ data }) {
  const posts = nicer(data.posts)
  const projects = nicer(data.projects, { category: true, anchor: '/projects' })
  const interests = nicer(data.interests, { category: true, blurb: true })
  return (
    <Layout>
      <div css={styles.hero}>
        <div css={styles.heroInner}>
          <Wave />
          <h1>Karolis Narkevicius</h1>
          <p>
            Welcome to my very own corner of the web. I'm a Software Engineer and a Co-Founder at{' '}
            <a href='https://humaans.io'>Humaans</a>, where we're building the best in class modern HR software with
            everything you need to onboard, manage and grow your employees. I mostly use JavaScript because of it's
            versatility and ongoing evolution. I am striving to create high quality products that help people do more. I
            am interested in the re-decentralisation of the web and more generally in ensuring technology creates a
            sustainable future.
          </p>
          <ul css={styles.social}>
            {[
              { label: 'Twitter', url: 'https://twitter.com/KidkArolis' },
              { label: 'GitHub', url: 'https://github.com/KidkArolis' },
              { label: 'hello@kn8.lt', url: 'mailto:hello@kn8.lt' }
            ].map(s => (
              <li key={s.label}>
                <a href={s.url} target='_blank' rel='noopener'>
                  {s.label}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <main id={website.skipNavId} css={styles.main}>
        <Title>Blog</Title>
        <Listing items={posts} compact />
        <Link to='/blog'>See more →</Link>

        <Title>Projects</Title>
        <Listing items={projects} compact />
        <Link to='/projects'>See more →</Link>

        <Title>Interests</Title>
        <Subtitle>
          These are the projects that are actively being developed out there and that I find the most interesting at the
          moment. Right now I am just an observer, but am always on the lookout for an opportunity and time to
          contribute one way or another.
        </Subtitle>
        <Listing items={interests} />
        <Link to='/interests'>See more →</Link>
      </main>
    </Layout>
  )
}

Index.propTypes = {
  data: PropTypes.object.isRequired
}

const styles = {
  hero: {
    background: 'linear-gradient(rgb(255, 239, 228), white)',
    [theme.media.desktop]: {
      background: 'linear-gradient(rgb(255,247,241), white)',
      marginLeft: 80,
      marginRight: 80,
      paddingLeft: 80,
      paddingRight: 80
    }
  },
  heroInner: {
    maxWidth: theme.maxWidth,
    margin: '0 auto',
    padding: '4rem 1.25rem 0rem 1.25rem',
    [theme.media.desktop]: {
      padding: '8rem 2rem 4rem 2rem'
    }
  },
  subtitle: {
    fontSize: 22
  },
  social: {
    listStyleType: 'none',
    display: 'flex',
    marginLeft: 0,
    marginBottom: 0,
    li: {
      display: 'inline',
      marginLeft: 0,
      marginRight: '2.5rem',
      marginBottom: 0
    },
    a: {
      color: 'black',
      fontWeight: 600,
      fontSize: '80%'
    }
  },
  main: {
    maxWidth: theme.maxWidth,
    margin: '0 auto',
    padding: '2rem 1.25rem 2rem 1.25rem',
    [theme.media.desktop]: {
      padding: '2rem 2rem 2rem 2rem'
    }
  }
}

export const pageQuery = graphql`
  fragment markdown on MarkdownRemarkConnection {
    edges {
      node {
        id
        excerpt
        fields {
          slug
        }
        html
        timeToRead
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          category
          link
        }
      }
    }
  }
  query {
    posts: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fields: { kind: { eq: "blog" } } }
      limit: 3
    ) {
      ...markdown
    }

    projects: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fields: { kind: { eq: "projects" } } }
      limit: 3
    ) {
      ...markdown
    }

    interests: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fields: { kind: { eq: "interests" } } }
      limit: 3
    ) {
      ...markdown
    }
  }
`
